import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Website from "../website/Website";
import Login from "../authentication/login/Login";
import Register from '../authentication/register/Register';
import Dashboard from '../admin/dashboard/Dashboard'
import Transcription from '../admin/transcription/Transcription';
import Subscription from '../admin/subscription/Subscription';
import PurchaseHistory from '../admin/purchase-history/PurchaseHistory';
import User from '../admin/user/User';
import ContactMessage from '../admin/contact-message/ContactMessage';
import Setting from '../admin/setting/Setting';
import Profile from '../admin/profile/Profile';
import Templates from "../admin/templates/Templates";
import TranscriptionHistory from "../admin/transcription/TranscriptionHistory";


export default function Layout() {
  function RoutingSection() {
    const currentUrl =
      useLocation().pathname.replace(/^\//, "").split("/")[0] || "website";

    return (
      <>
        <Routes>
          <Route path="/" element={<Website />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/template" element={<Templates /> } />
          <Route path="/admin/subscription" element={<Subscription />} />
          <Route path="admin/transcription" element={<Transcription />} />
          <Route path="admin/session" element={<TranscriptionHistory />} />
          <Route path="admin/session/:id" element={<TranscriptionHistory />} />
          <Route path="/admin/purchase-history" element={<PurchaseHistory />} />
          <Route path="/admin/user" element={<User />} />
          <Route path="/admin/contact-message" element={<ContactMessage />} />
          <Route path="/admin/setting" element={<Setting />} />
        </Routes>
      </>
    );
  }

  return (
    <>
      <Router>
        <RoutingSection />
      </Router>
    </>
  );
}
