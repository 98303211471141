import React, { useState, useEffect, useRef } from "react";
// import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import RichTextEditor from "react-rte";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import axios from "axios";

export default function NewTemplates({
  isOpen,
  toggle,
  fetechTemplates,
  editingTemplate,
}) {
  const [backdrop, setBackdrop] = useState(false);
  const [templateName, setTemplateName] = useState(
    editingTemplate?.heading || ""
  );
  const [note, setNote] = useState(editingTemplate?.note || "Note");
  const [details, setDetails] = useState(editingTemplate?.details || "");
  const quillRef = useRef(null);
  const handleSave = () => {
    const payload = {
      heading: templateName,
      note: note,
      details: details,
      user_id: localStorage.getItem("user_id"),
    };

    if (editingTemplate) {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/dr_templates/${editingTemplate.id}`,
          payload
        )
        .then((response) => {
          console.log("Template updated successfully:", response.data);
          toggle();
          resetFoam();
          fetechTemplates(); // Fetch updated templates after updating
        })
        .catch((error) => {
          console.error("Error updating template:", error);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/dr_templates`, payload)
        .then((response) => {
          console.log("Template saved successfully:", response.data);
          toggle();
          resetFoam();
          fetechTemplates(); // Fetch updated templates after adding
        })
        .catch((error) => {
          console.error("Error saving template:", error);
        });
    }
  };

  const resetFoam = () => {
    setTemplateName("");
    setNote("");
    setDetails("");
  };

  useEffect(() => {
    console.log("2");
    if (editingTemplate) {
      setTemplateName(editingTemplate.heading);
      setNote(editingTemplate.note);
      setDetails(editingTemplate.details);
    } else {
      resetFoam();
    }
  }, [editingTemplate]);

  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} backdrop={backdrop} size="lg">
        <ModalHeader toggle={toggle}>
          {editingTemplate ? "Edit Template" : "Add New Template"}
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                {/* <Label for="exampleEmail">Email</Label> */}
                <Input
                  name="heading"
                  type="text"
                  placeholder="Template Name"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </Col>
              <Col>
                <Input
                  id="note"
                  name="note"
                  type="select"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                >
                  <option>Note</option>
                  <option>Document</option>
                </Input>
              </Col>
            </Row>
            <br />

            <Row>
              <Label for="details">Template Details</Label>
              {/* <ReactQuill
                theme="snow"
                ref={quillRef}
                id="details"
                value={details}
                onChange={setDetails}
              /> */}
              {/* <textarea
                id="details"
                // style={{ marginLeft: "13px",marginRight: "20px" }}
                value={details}
                onChange={setDetails}
                rows='6'
                placeholder="Type something..."
              ></textarea> */}
              <textarea
  id="details"
  value={details}
  onChange={(e) => setDetails(e.target.value)} // Make sure to extract the value as a string
  rows="6"
  placeholder="Type something..."
></textarea>

              {/* <RichTextEditor

              /> */}
              {/* ; */}
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSave}>
            {editingTemplate ? "Update" : "Save"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => {
              toggle();
              resetFoam(); // Reset the form when canceled
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
