export default function Address(){
    return(
        <>
             <section className="bg-body-emphasis">
          <div className="container-small px-lg-7 px-xxl-3">
            <div className="mb-3 text-center text-sm-start">
              <h4 className="text-primary fa-9 mb-3">Address</h4>
              <h2 className="fa-9"
              style = {{ fontSize:'30px' }}
              >
                If you need to find us:
              </h2>
            </div>
            <p className="column-md-2 fa-9">
              Do not lose your potential customers to others. Tell them exactly
              where you are with Geolocation enabled Phoenix admin dashboard, No
              need to take the burden of communicating directly. You can easily
              tell your customers where to find you with precise location map.
              Getting closer was never easier!
            </p>
            <div className="row">
              <div className="col-12 mb-15">
                <div className="mapbox-container rounded-3 border overflow-hidden mt-3 mb-6">
                  <div
                    id="mapbox"
                    data-mapbox='{"attributionControl":false,"center":[-74.0020158,40.7228022],"zoom":14,"scrollZoom":false}'
                    style={{
                      height: "381px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="row g-5 g-lg-5">
              <div className="col-md-6 mb-5 mb-md-0 text-center text-md-start">
                <h3 className="fa-9 mb-3" style={{ fontSize: "22px" }}>
                  Stay connected
                </h3>
                <p className="fa-9 mb-5">
                  Stay connected with Phoenix's Help Center; Phoenix is
                  available for your necessities at all times.
                </p>
                <div className="d-flex flex-column align-items-center align-items-md-start gap-3 gap-md-0">
                  <div className="d-md-flex align-items-center">
                    <div className="icon-wrapper shadow-info">
                      <span
                        className="uil uil-phone text-primary fs-4 z-1 ms-2"
                        data-bs-theme="light"
                      ></span>
                    </div>
                    <div className="flex-1 ms-3">
                      <a className="link-900 fa-9">
                        (871) 406-7509
                      </a>
                    </div>
                  </div>
                  <div className="d-md-flex align-items-center">
                    <div className="icon-wrapper shadow-info">
                      <span
                        className="uil uil-envelope text-primary fs-4 z-1 ms-2"
                        data-bs-theme="light"
                      ></span>
                    </div>
                    <div className="flex-1 ms-3">
                      <a
                        className="fa-9 text-body"
                      >
                        phoenix@email.com
                      </a>
                    </div>
                  </div>
                  <div className="mb-6 d-md-flex align-items-center">
                    <div className="icon-wrapper shadow-info">
                      <span
                        className="uil uil-map-marker text-primary fs-4 z-1 ms-2"
                        data-bs-theme="light"
                      ></span>
                    </div>
                    <div className="flex-1 ms-3">
                      <a className="fa-9 text-body">
                        39163 Amir Drive Suite 802
                      </a>
                    </div>
                  </div>
                  <div className="d-flex">
                    <a>
                      <span className="fa-brands fa-facebook fs-6 text-primary me-3"></span>
                    </a>
                    <a>
                      <span className="fa-brands fa-twitter fs-6 text-primary me-3"></span>
                    </a>
                    <a>
                      <span className="fa-brands fa-linkedin-in fs-6 text-primary"></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center text-md-start">
                <h3 className="fa-9 mb-3" style={{ fontSize: "22px" }}>
                  Drop us a line
                </h3>
                <p className="fa-9 mb-7">
                  If you have any query or suggestion , we are open to learn
                  from you, Lets talk, reach us anytime.
                </p>
                <form className="row g-4">
                  <div className="col-12">
                    <input
                      className="form-control bg-body-emphasis"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required="required"
                    />
                  </div>
                  <div className="col-12">
                    <input
                      className="form-control bg-body-emphasis"
                      type="email"
                      name="email"
                      placeholder="Email"
                      required="required"
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      className="form-control bg-body-emphasis"
                      rows="6"
                      name="message"
                      placeholder="Message"
                      required="required"
                    ></textarea>
                  </div>
                  <div className="col-12 d-grid">
                    <button
                      className="btn btn-outline-primary fa-9"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="feedback"></div>
                </form>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}