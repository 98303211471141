export default function Subscribe(){
    return(
        <>
                    <section className="bg-body-emphasis pb-0">
          <div className="container-small px-lg-7 px-xxl-3">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <div className="card py-md-9 px-md-13 border-0 z-1 shadow-lg cta-card">
                  <div
                    className="bg-holder"
                    style={{
                      backgroundImage: "url(assets/img/bg/bg-18.png)",
                      backgroundPosition: "right",
                      backgroundSize: "auto",
                    }}
                  ></div>

                  <div className="card-body position-relative">
                    <img
                      className="img-fluid mb-5 d-dark-none"
                      src="assets/img/spot-illustrations/27.png"
                      width="210"
                      alt="..."
                    />
                    <img
                      className="img-fluid mb-5 d-light-none"
                      src="assets/img/spot-illustrations/dark_27.png"
                      width="210"
                      alt="..."
                    />
                    <div className="d-flex align-items-center fw-bold justify-content-center mb-3">
                      <p className="mb-0 fa-9">2008 Premium Icons </p>
                      <svg
                        className="svg-inline--fa fa-circle text-primary"
                        data-fa-transform="shrink-12"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="circle"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        data-fa-i2svg=""
                        style={{
                          transformOrigin: "0.5em 0.5em",
                        }}
                      >
                        <g transform="translate(256 256)">
                          <g transform="translate(0, 0)  scale(0.25, 0.25)  rotate(0 0 0)">
                            <path
                              fill="currentColor"
                              d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z"
                              transform="translate(-256 -256)"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      <p className="mb-0 fa-9">Included FREE with it</p>
                    </div>
                    <h1
                      className="fs-6 fs-sm-4 fs-lg-2 fk-9 lh-sm mb-3"
                      style={{ fontSize: "28px" }}
                    >
                      Join
                      <span className="gradient-text-primary mx-2">
                        Phoenix
                      </span>
                      <span>Today</span>
                    </h1>
                    <form className="d-flex justify-content-center mb-3 px-xxl-12">
                      <div className="d-grid d-sm-block"></div>
                      <input
                        className="form-control me-3"
                        id="ctaEmail1"
                        type="email"
                        placeholder="Email"
                        aria-describedby="ctaEmail1"
                      />
                      <button className="btn btn-primary fa-9" type="submit">
                        Subscribe
                      </button>
                    </form>
                    <p className="fa-9">
                      Best support in the world, Only Phoenix can ensure{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    )
}