import AdminHeader from "../admin-header/AdminHeader";

export default function Dashboard() {
  return (
    <>
      <main className="main" id="top">
        <AdminHeader />
        <div className="content">
          <div className="pb-6 mt-4">
            <div className="px-3 mb-6">
              <div className="row justify-content-between">
                <div
                  style={{
                    textAlign: "center",
                    fontFamily: '"San Francisco", Arial, sans-serif',
                  }}
                  className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-xxl-0 border-bottom-xxl-0 border-end border-bottom pb-4 pb-xxl-0 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={45}
                    height={45}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx={12} cy={7} r={4} />
                    <path d="M5 22c0-4 3-7 7-7s7 3 7 7" />
                  </svg>
                  <h1 className="fs-3 pt-3">2,800</h1>
                  <p className="mb-0">Total Patient</p>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontFamily: '"San Francisco", Arial, sans-serif',
                  }}
                  className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-xxl-0 border-bottom-xxl-0 border-end-md border-bottom pb-4 pb-xxl-0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={45}
                    height={45}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect x={3} y={4} width={18} height={18} rx={2} ry={2} />
                    <line x1={16} y1={2} x2={16} y2={6} />
                    <line x1={8} y1={2} x2={8} y2={6} />
                    <line x1={3} y1={10} x2={21} y2={10} />
                    <circle cx={8} cy={14} r={1} />
                    <circle cx={12} cy={14} r={1} />
                    <circle cx={16} cy={14} r={1} />
                  </svg>
                  <h1 className="fs-3 pt-3">1,866</h1>
                  <p className="mb-0">Total Appointments</p>
                </div>
                <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-bottom-xxl-0 border-bottom border-end border-end-md-0 pb-4 pb-xxl-0 pt-4 pt-md-0">
                  <svg
                    className="confirmed-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx={12} cy={12} r={10} />

                    <path d="M8 12l2 2l4 -4" />
                  </svg>
                  <h1 className="fs-3 pt-3">1,366</h1>
                  <p className="mb-0">Confirmed</p>
                </div>
                <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-md border-end-xxl-0 border-bottom border-bottom-md-0 pb-4 pb-xxl-0 pt-4 pt-xxl-0">
                  <svg
                    className="pending-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect x={3} y={4} width={18} height={18} rx={2} ry={2} />

                    <line x1={3} y1={10} x2={21} y2={10} />

                    <circle cx={12} cy={14} r={6} />
                    <line x1={12} y1={9} x2={12} y2={13} />
                    <line x1={12} y1={16} x2={12} y2={16} />
                  </svg>
                  <h1 className="fs-3 pt-3">1,200</h1>
                  <p className="mb-0">Pending</p>
                </div>
                <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end border-end-xxl-0 pb-md-4 pb-xxl-0 pt-4 pt-xxl-0">
                  <svg
                    className="today-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect x={3} y={4} width={18} height={18} rx={2} ry={2} />

                    <line x1={3} y1={10} x2={21} y2={10} />

                    <path d="M6 12l4 4L18 8" />
                  </svg>
                  <h1 className="fs-3 pt-3">900</h1>
                  <p className="mb-0">Today Collection</p>
                </div>
                <div className="col-6 col-md-4 col-xxl-2 text-center border-translucent border-start-xxl border-end-xxl pb-md-4 pb-xxl-0 pt-4 pt-xxl-0">
                  <svg
                    className="pharmacy-sale-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <rect x={6} y={4} width={12} height={16} rx={3} ry={3} />

                    <rect x={6} y={2} width={12} height={2} rx={1} ry={1} />

                    <text
                      x={12}
                      y={14}
                      fontSize={10}
                      textAnchor="middle"
                      fill="black"
                    >
                      $
                    </text>
                  </svg>
                  <h1 className="fs-3 pt-3">500</h1>
                  <p className="mb-0">Pharmacy Sale</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="col-xxl-6 mb-6">
              <h3 className="fs-6">Patients Bookings/Visits</h3>
              <div
                className="echart-contacts-created"
                style={{ minHeight: 270, width: "100%" }}
              />
            </div>
          </div>
          <div
            className="modal fade"
            id="tooltipModal"
            tabIndex={-1}
            aria-labelledby="tooltipModalLabel"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <form action="config_profile" method="POST">
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                    autoComplete="off"
                  />
                  <div className="modal-body">
                    <div align="center">
                      <img
                        id="imageProfilePreview"
                        src="img/cover20240825061625.png"
                        width={100}
                        alt="image preview"
                      />
                      <h5>Set up your Medical App</h5>
                      <p>
                        {" "}
                        Let us know a bit more about you and we’ll optimize the
                        type of content you see on Medical App for you{" "}
                      </p>
                    </div>
                    <hr />
                    <label htmlFor="organizerSingle">Speciality</label>
                    <select
                      className="form-select"
                      id="speciality"
                      name="speciality"
                      data-choices="data-choices"
                      data-options='{"removeItemButton":true,"placeholder":true}'
                    >
                      <option value="">Select your speciality...</option>
                      <option value="Cardiology">Cardiology</option>
                      <option value="Neurology">Neurology</option>
                      <option value="Pediatrics">Pediatrics</option>
                      <option value="Dermatology">Dermatology</option>
                      <option value="Oncology">Oncology</option>
                      <option value="Orthopedics">Orthopedics</option>
                      <option value="Psychiatry">Psychiatry</option>
                      <option value="Gastroenterology">Gastroenterology</option>
                      <option value="Pulmonology">Pulmonology</option>
                      <option value="Endocrinology">Endocrinology</option>
                    </select>
                    <label htmlFor="organizerSingle">Country</label>
                    <select
                      className="form-select"
                      id="country"
                      name="country"
                      data-choices="data-choices"
                      data-options='{"removeItemButton":true,"placeholder":true}'
                    >
                      <option value="">Select your Country...</option>
                      <option value="United States">United States</option>
                      <option value="Canada">Canada</option>
                      <option value="Brazil">Brazil</option>
                      <option value="Germany">Germany</option>
                      <option value="India">India</option>
                      <option value="China">China</option>
                      <option value="Australia">Australia</option>
                      <option value="Japan">Japan</option>
                      <option value="South Africa">South Africa</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="France">France</option>
                      <option value="Italy">Italy</option>
                      <option value="Russia">Russia</option>
                      <option value="Mexico">Mexico</option>
                      <option value="Egypt">Egypt</option>
                      <option value="Pakistan">Pakistan</option>
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-primary" type="submit">
                      Customize My Experience
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="tooltipModalsecond"
            tabIndex={-1}
            aria-labelledby="tooltipModalLabel"
            aria-hidden="true"
            style={{ display: "none" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <form action="config_profile_second" method="POST">
                  <input
                    type="hidden"
                    name="_token"
                    defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                    autoComplete="off"
                  />
                  <div className="modal-body">
                    <div align="center">
                      <img
                        id="imageProfilePreview"
                        src="img/cover20240825061625.png"
                        width={100}
                        alt="image preview"
                      />
                      <h5>Set up your Medical App </h5>
                      <p>
                        {" "}
                        Let us know a bit more about your organization and we’ll
                        optimize the type of content you see on Medical App for
                        you{" "}
                      </p>
                    </div>
                    <hr />
                    <label htmlFor="organizerSingle">Organization Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Organization Name"
                      name="org_name"
                    />
                    <label htmlFor="organizerSingle">
                      How manay clinicians do you work with
                    </label>
                    <select className="form-select" id="myteam" name="myteam">
                      <option value="Just Me">Just me</option>
                      <option value="2 - 5">2 - 5</option>
                      <option value="6 - 20">6 - 20</option>
                      <option value="21 - 50">21 - 50</option>
                      <option value="51+">51+</option>
                    </select>
                    <label htmlFor="organizerSingle">
                      Your Role in Organization
                    </label>
                    <select className="form-select" id="myrole" name="myrole">
                      <option value="">Select your Role...</option>
                      <option value="Hospital Administrator">
                        Hospital Administrator
                      </option>
                      <option value="Physical Therapist">
                        Physical Therapist
                      </option>
                      <option value="Medical Laboratory Technologist">
                        Medical Laboratory Technologist
                      </option>
                      <option value="Clinical Pharmacist">
                        Clinical Pharmacist
                      </option>
                      <option value="Nurse Practitioner">
                        Nurse Practitioner
                      </option>
                      <option value="Emergency Room Physician">
                        Emergency Room Physician
                      </option>
                      <option value="Radiologist">Radiologist</option>
                      <option value="Anesthesiologist">Anesthesiologist</option>
                      <option value="Surgeon">Surgeon</option>
                      <option value="Chief Medical Officer">
                        Chief Medical Officer
                      </option>
                    </select>
                  </div>
                  <div className="modal-footer">
                    <button className="btn btn-primary" type="submit">
                      Customize My Experience
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <footer className="footer position-absolute">
            <div className="row g-0 justify-content-between align-items-center h-100">
              <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 mt-2 mt-sm-0 text-900">
                  All rights reserved
                  <span className="d-none d-sm-inline-block" />
                  <span className="d-none d-sm-inline-block mx-1">|</span>
                  <br className="d-sm-none" />
                  2024 ©
                  {/* <a className="mx-1" href="#"> */}
                    Medical App
                  {/* </a> */}
                </p>
              </div>
            </div>
          </footer>
        </div>
      </main>
    </>
  );
}
/*

*/
