import { Link } from "react-router-dom";

export default function AdminHeader() {
  return (
    <>
      <header className="header">
        <nav className="navbar_container">
          <ul className="nav_list">
            <li className="nav_items">
              <Link to="/">
                <div
                  className="d-flex align-items-center"
                  style={{ fontWeight: 800, fontSize: 24 }}
                >
                  Medical App
                </div>
              </Link>
            </li>
            <li className="nav_items">
            <Link to="/admin/dashboard">Dashboard</Link>
            </li>
            <li className="nav_items">
              <Link to="/admin/transcription">Transcription</Link>
            </li>

            <li className="nav_items">
              {/* <a to="/admin/template">Templates</a> */}
              <Link to="/admin/template">Templates</Link>
            </li>
            <li className="nav_items">
              {/* <a to="/admin/subscription">Subscription</a> */}
              <Link to="/admin/subscription">Subscription</Link>
            </li>
            <li className="nav_items">
              {/* <a to="/admin/purchase-history">Purchase Hisotry</a> */}
              <Link to="/admin/purchase-history">Purchase Hisotry</Link>
            </li>
            <li className="nav_items">
              {/* <a to="/admin/user">Users</a> */}
              <Link to="/admin/user">Users</Link>
            </li>
            <li className="nav_items">
              {/* <a to="/admin/contact-message">Contact Messages</a> */}
              <Link to="/admin/contact-message">Contact Messages</Link>
            </li>
            <li>
              <Link
                className="nav-link dropdown-toggle"
                to="/admin/setting"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Website Settings
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Use Cases</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Pricing Packages</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Settings</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Special Features</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Who we are</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Team</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Our Clients</span>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="">
                    <div className="dropdown-item-wrapper">
                      <span>Testimonials</span>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <ul className="navbar d-flex align-items-center">
              <li className="nav-items dropdown ms-auto">
                <Link
                  className="nav-link lh-1 pe-0 white-space-nowrap"
                  id="navbarDropdownUser"
                  to="#!"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  data-bs-auto-close="outside"
                  aria-expanded="false"
                >
                  Shoaib <span className="fa-solid fa-chevron-down fs--2" />
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300"
                  aria-labelledby="navbarDropdownUser"
                >
                  <div className="card position-relative border-0">
                    <div className="card-body p-0">
                      <div className="text-center pt-4 pb-1">
                        <div className="avatar avatar-xl">
                          <img
                            className="rounded-circle"
                            src="assets/img/nav-icons/32.webp"
                            alt=""
                          />
                        </div>
                        <h6 className="mt-2 text-black">Shoaib Khan</h6>
                        <h6 className="mt-2 text-black">Login as admin</h6>
                        <hr />
                      </div>
                    </div>
                    <div
                      className="overflow-auto scrollbar"
                      style={{ height: "5rem" }}
                    >
                      <ul className="nav d-flex flex-column mb-1 pb-1">
                        <li className="nav-item">
                          <Link className="nav-link px-3" to="dashboard.html">
                            <span
                              className="me-2 text-900"
                              data-feather="settings"
                            />
                            Dashboard
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link px-3" to="/admin/profile">
                            <span
                              className="me-2 text-900"
                              data-feather="user"
                            />
                            {/* <span>Profile</span> */}
                            <Link to="/admin/profile">Profile</Link>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="card-footer p-0">
                      <div className="px-3">
                        <form method="POST" action="logout">
                          <input
                            type="hidden"
                            name="_token"
                            defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                            autoComplete="off"
                          />
                          <Link
                            className="btn btn-phoenix-secondary d-flex flex-center w-100"

                            onclick="event.preventDefault(); this.closest('form').submit();"
                          >
                            <span className="me-2" data-feather="log-out">
                              {" "}
                            </span>
                            Sign out
                          </Link>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </ul>
        </nav>
        {/* For Mobie */}
        <nav className="mobile_navbar_container">
          <ul className="mobile_navbar_list">
            <li className="mobile_nav_items">
              <Link className="menu_button" onclick="openMenu(this)">
                <div className="line1" />
                <div className="line2" />
              </Link>
            </li>
            <li className="mobile_nav_items">
              <Link className="navbar-brand navbar-brand" to="">
                <div
                  className="d-flex align-items-center"
                  style={{ fontWeight: 800, fontSize: 18 }}
                >
                  Medical App
                </div>
              </Link>
            </li>
            <li className="mobile_nav_items dropdown">
              <Link
                className="nav-link lh-1 pe-0 white-space-nowrap"
                id="navbarDropdownUser"
                to="#!"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                {" "}
                Shoaib <span className="fa-solid fa-chevron-down fs--2" />
              </Link>
              <div
                className="dropdown-menu dropdown-menu-end navbar-dropdown-caret py-0 dropdown-profile shadow border border-300"
                aria-labelledby="navbarDropdownUser"
              >
                <div className="card position-relative border-0">
                  <div className="card-body p-0">
                    <div className="text-center pt-4 pb-1">
                      <div className="avatar avatar-xl ">
                        <img
                          className="rounded-circle "
                          src="profile/cover20240826162338.png"
                          alt=""
                        />
                      </div>
                      <h6 className="mt-2 text-black">Shoaib Khan</h6>
                      <h6 className="mt-2 text-black">Login as admin</h6>
                      <hr />
                    </div>
                  </div>
                  <div
                    className="overflow-auto scrollbar"
                    style={{ height: "5rem" }}
                  >
                    <ul className="nav d-flex flex-column mb-1 pb-1">
                      <li className="nav-item">
                        <Link className="nav-link px-3" to="dashboard.html">
                          <span
                            className="me-2 text-900"
                            data-feather="settings"
                          />
                          Dashboard
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link px-3" to="profile.html">
                          {" "}
                          <span className="me-2 text-900" data-feather="user" />
                          <span>Profile</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="card-footer p-0">
                    <div className="px-3">
                      <form method="POST" action="logout">
                        <input
                          type="hidden"
                          name="_token"
                          defaultValue="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                          autoComplete="off"
                        />{" "}
                        <Link
                          className="btn btn-phoenix-secondary d-flex flex-center w-100"

                          onclick="event.preventDefault(); this.closest('form').submit();"
                        >
                          <span className="me-2" data-feather="log-out">
                            {" "}
                          </span>
                          Sign out
                        </Link>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
        {/* expansion */}
        <div className="expansionMenu">
          <div className="mobile_item_menu_container">
            <ul className="mobile_item_menu">
              <li className="mobile_menu_items">
                <Link to="dashboard.html" className="mobile_menu_item_links">
                  Home
                </Link>
              </li>
              <li className="mobile_menu_items">
                <Link to="transcribenew.html" className="mobile_menu_item_links">
                  New Session
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  className="dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {" "}
                  Sessions
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Untitled Session</span>
                        <br />
                        <span>2024-11-14 08:07:05</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Untitled Session</span>
                        <br />
                        <span>2024-10-22 23:57:35</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Untitled Session</span>
                        <br />
                        <span>2024-10-22 18:57:29</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Untitled Session</span>
                        <br />
                        <span>2024-09-03 06:58:30</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Untitled Session</span>
                        <br />
                        <span>2024-08-29 09:51:03</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Untitled Session</span>
                        <br />
                        <span>2024-08-29 09:41:52</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="mobile_menu_items">
                <Link to="templates.html" className="mobile_menu_item_links">
                  Templates
                </Link>
              </li>
              <li className="mobile_menu_items">
                <Link to="subscription.html" className="mobile_menu_item_links">
                  Subscription
                </Link>
              </li>
              <li className="mobile_menu_items">
                <Link to="history.html" className="mobile_menu_item_links">
                  Purchase Hisotry
                </Link>
              </li>
              <li className="mobile_menu_items">
                <Link to="users.html" className="mobile_menu_item_links">
                  Users
                </Link>
              </li>
              <li className="mobile_menu_items">
                <Link to="" className="mobile_menu_item_links">
                  Contact Messages
                </Link>
              </li>
              <li className="dropdown">
                <Link
                  className="dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {" "}
                  Website Settings
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Use Cases</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Pricing Packages</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Settings</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Specia Features</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Who we are</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Team</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Oue Clients</span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="">
                      <div className="dropdown-item-wrapper">
                        <span>Testimonials</span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
}
