import AdminHeader from "../admin-header/AdminHeader";

export default function ContactMessage(){
    return(
        <>
        <main className="main" id="top">
          {/*<nav class="navbar navbar-top navbar-slim fixed-top navbar-expand" id="topNavSlim">*/}
          <AdminHeader />
          <div className="content">
            <footer className="footer position-absolute">
              <div className="row g-0 justify-content-between align-items-center h-100">
                <div className="col-12 col-sm-auto text-center">
                  <p className="mb-0 mt-2 mt-sm-0 text-900">
                    All rights reserved
                    <span className="d-none d-sm-inline-block" />
                    <span className="d-none d-sm-inline-block mx-1">|</span>
                    <br className="d-sm-none" />
                    2024 ©
                    {/* <a className="mx-1" href="#">
                      Medical App
                    </a> */}
                  </p>
                </div>
              </div>
            </footer>
          </div>
        </main>
      </>

    )
}