import { useEffect, useState } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { TabPane, TabContent, NavLink, Nav, NavItem } from "reactstrap";
import { FiTrash2 } from "react-icons/fi";
import AdminHeader from "../admin-header/AdminHeader";
import copy from 'copy-to-clipboard';

import $ from "jquery";
window.$ = $;

export default function TranscriptionHistory() {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState("12");
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const [patientName, setPatientName] = useState("Default Patient");
  const [recordingStatus, setRecordingStatus] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioTime, setAudioTime] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [tabdata, setTabdata] = useState([]);
  const [randomNumber, setRandomNumber] = useState();
  //   const [ text, setText ] = useState("mazhar")
  const [activeTab, setActiveTab] = useState("transcription");

  const currentUrl = useLocation().pathname.replace(/^\//, "").split("/")[2];

  const fetechTemplates = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/dr_templates`
      );
      setTemplates(response.data);
      if (response.data && response.data.length > 0) {
        setSelectedTemplate(response.data[0].id); // Assuming 'id' is the key
      }
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const generateRandomNumber = () => {
    return Math.floor(10000000 + Math.random() * 90000000).toString();
  };

  useEffect(() => {
    fetechTemplates();
    // Dynamically load external script
    const script = document.createElement("script");
    script.src = `${process.env.PUBLIC_URL}/assets/js/main.js`;
    script.async = true;
    document.body.appendChild(script);

    if (currentUrl) {
      console.log("currentUrl.", currentUrl);
      setRandomNumber(currentUrl);
      getRandomNumber(currentUrl);
      // getRandomNumber();
      // randomNumber = currentUrl;
    } else {
      console.log("else");
      const newRandom = generateRandomNumber();
      setRandomNumber(newRandom);
      // setRandomNumber(generateRandomNumber());
      getRandomNumber(newRandom);
      // randomNumber = ;
    }

    console.log("123", randomNumber);
    // Cleanup the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /////////
  const getRandomNumber = (number) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/transcription_submission_check`, {
        randomno: number, // Send randomno as part of a JSON object
      })

      .then((response) => {
        setTabdata(response.data.formatted_text);
        setPatientName(response.data.patient_name || "");
        document.getElementById("contentToCopy").value = response.data.text;
        document.getElementById("transcript").value = response.data.text;

        //   alert("Transcription submitted successfully!");
      })
      .catch((error) => {
        console.error("Error getting random number:", error);
        // alert("Failed to get random number. Please try again.");
      });
  };

  // Handle template selection change
  const handleTemplateChange = () => {
    let val = document.getElementById("contentToCopy").value;
    console.log("val", val);
    if (val.length) {
      const payload = {
        text: val,
        randomno: randomNumber,
        template: selectedTemplate,
      };
      console.log("payload", payload);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/transcription_submission_temp`,
          payload
        )
        .then((response) => {
          // setTabdata(response.data.formatted_text);
          if(response.data.statusCode===201){
            getRandomNumber(randomNumber)
          }
        })
        .catch((error) => {
          console.error("Error saving new template:", error);
          //   alert("Failed to submit transcription. Please try again.");
        });

      //transcription_submission_temp
    } else if (!val.length) {
      return alert("Please Add your details");
    }
  };

  // Handle language selection change
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleDelete = (id) => {
    console.log("Delete clicked for template ID:", id);
    axios
      .delete(`${process.env.REACT_APP_API_URL}/api/formated_transcription_template/${id}`)
      .then(() => {
        console.log("Template deleted successfully");
        // window.location.reload();

        // window.NavigationPreloadManager()
        // Fetch updated templates after deletion
      })
      .catch((error) => {
        console.error("Error deleting template:", error);
      });
  };

  // Handle patient name change
  const handlePatientNameChange = (event) => {
    setPatientName(event.target.value);
  };

  //   const handleRecordingToggle = () => {
  //     if (!intervalId) {
  //       // Start recording
  //       const interval = setInterval(() => {
  //         setAudioTime((prevTime) => prevTime + 1); // Increment the time by 1 second
  //       }, 1000);
  //       setIntervalId(interval); // Store the interval ID for clearing it later
  //     } else {
  //       // Stop recording
  //       clearInterval(intervalId); // Stop the timer
  //       setIntervalId(null); // Clear the interval reference
  //     }
  //   };
  const handleRecordingToggle = () => {
    if (!isRecording) {
      // Start recording
      const interval = setInterval(() => {
        setAudioTime((prevTime) => prevTime + 1); // Increment the time by 1 second
      }, 1000);
      setIntervalId(interval); // Store the interval ID for clearing it later
      setIsRecording(true); // Update recording state
    } else {
      // Stop recording
      clearInterval(intervalId); // Stop the timer
      setIntervalId(null); // Clear the interval reference
      setIsRecording(false); // Update recording state

      // Call the stop button click handler
      handleStopButtonClick();
    }
  };

  const handleAudioTimeChange = (event) => {
    setAudioTime(event.target.value);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
  };

  const handleStopButtonClick = () => {
    if (isRecording) {
      const transcriptValue = document.getElementById("transcript").value;

      const payload = {
        text: transcriptValue,
        template: selectedTemplate,
        language: selectedLanguage,
        randomno: randomNumber,
        total_time: audioTime,
        user_id: localStorage.getItem("user_id"),
        patient_name: patientName,
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/transcription_submission`, payload)
        .then((response) => {
          // if (response.statusCode === 201) {
          setTabdata(response.data.formatted_text);
          // navigate(`/${randomNumber}`);
          navigate(`/admin/session/${randomNumber}`);
        })
        .catch((error) => {
          console.error("Error saving template:", error);
        });
    }
  };

  const handlePrintLogic = () => {};

  const handlePdfLogic = () => {};

  // const handleDocumentLogic = () => {};

  const handleCopyLogic = () => {

  };



  return (
    <main className="main" id="top">
      <AdminHeader />
      <div className="content">
        <div className="row align-items-center justify-content-between g-2">
          <div className="col col-auto">
            <div className="d-flex align-items-center">
              <form
                id="tempadd"
                action="updatetemptext"
                method="POST"
                style={{
                  fontFamily:
                    "SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial, sans-serif",
                }}
              >
                <input
                  type="hidden"
                  name="_token"
                  value="Zah9a2H5c9Lb6RQiFz5hiTc0UNPPJv2cGNmZHXqv"
                  autoComplete="off"
                />

                <div className="form-icon-container">
                  <div className="form-floating mb-3">
                    <select
                      name="myowntemp"
                      id="tempa"
                      className="form-control form-icon-input"
                      value={selectedTemplate} // Bind the select value to the state
                      // onClick={handleTemplateChange}
                      onChange={(e) => {
                        // setTemplates(e.target.value);
                        setSelectedTemplate(e.target.value);
                        handleTemplateChange();
                      }}
                    >
                      {/* Map over the templates and create an option for each */}
                      {Array.isArray(templates) && templates.length > 0 ? (
                        templates?.map((template) => (
                          <option key={template.id} value={template.id}>
                            {template.heading}{" "}
                            {/* Assuming 'name' is the template name */}
                          </option>
                        ))
                      ) : (
                        <option>Loading templates...</option> // Show loading text while fetching
                      )}
                    </select>
                    <label className="form-icon-label" htmlFor="tempa">
                      Template
                    </label>
                  </div>
                </div>
              </form>

              <div className="form-icon-container">
                <div className="form-floating mb-3">
                  <select
                    name="lng"
                    id="lng"
                    className="form-control form-icon-input"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    <option value="en-US">English US</option>
                    <option value="en-GB">English GB</option>
                    <option value="en-AU">English AU</option>
                    <option value="ar-SA">Arabic</option>
                    <option value="es-ES">Spanish</option>
                    <option value="de-DE">German</option>
                    <option value="fr-FR">French</option>
                    <option value="it-IT">Italian</option>
                  </select>
                  <label className="form-icon-label" htmlFor="floatingInput">
                    Language
                  </label>
                </div>
              </div>

              <div className="form-icon-container">
                <div className="form-floating mb-3">
                  <input
                    className="form-control form-icon-input"
                    id="ptname"
                    type="text"
                    value={patientName}
                    onChange={handlePatientNameChange}
                  />
                  <label className="form-icon-label" htmlFor="floatingInput">
                    Patient Name
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-auto">
            <div className="d-flex align-items-center">
              <button
                className="btn btn-phoenix-secondary me-1 mb-1"
                type="button"
                id="timer"
              >
                {formatTime(audioTime)}
              </button>
              <div className="btn-group mb-1 me-1">
                <button
                  className="btn btn-success"
                  type="button"
                  id={isRecording ? "stop-button" : "start-button"}
                  onClick={handleRecordingToggle}
                >
                  {isRecording ? "Stop Transcribing" : "Start Transcribing"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="liveaudio">
          <div className="row mt-1">
            <div className="col-md-2">
              <input type="hidden" name="title" value="" id="title" />
              <input
                type="hidden"
                id="temps"
                value={selectedTemplate}
                name="temps"
              />
              <input
                type="hidden"
                name="randomno"
                value="92808722097517735"
                className="form-control"
                id="randomno"
              />
              <input type="hidden" id="audiotime" name="audiotime" />
            </div>
          </div>
          <p>{recordingStatus}</p>
          <hr />
        </div>

        <input
          type="hidden"
          id="access_id"
          placeholder="ACCESS ID"
          value="AKIAX5ZI57UBWIKJQOW4"
        />
        <input
          type="hidden"
          id="secret_key"
          placeholder="SECRET KEY"
          value="slQcpML5RIQieftBJp6NAfuo/B3LDjITOyYiFZp6"
        />
        <input
          type="hidden"
          id="session_token"
          placeholder="SESSION TOKEN"
          value=""
        />
        <input
          type="hidden"
          id="region"
          placeholder="SESSION TOKEN"
          value="ap-south-1"
        />

        <div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "transcription" ? "active" : ""}
                onClick={() => toggleTab("transcription")}
              >
                Transcription
              </NavLink>
            </NavItem>

            {tabdata.map((it, index) => {
              return (
                <>
                  <NavItem>
                    <NavLink
                      className={activeTab === it.id ? "active" : ""}
                      onClick={() => toggleTab(it.id)}
                    >
                      {it.heading}
                    </NavLink>
                  </NavItem>
                </>
              );
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="transcription">
              <input type="hidden" id="transcript" />
              <div className="mt-2">
                <textarea
                  cols="150"
                  rows="20"
                  className="form-control"
                  id="contentToCopy"
                ></textarea>
              </div>
            </TabPane>
            {tabdata.map((it, index) => {
              const plainText = it.text.replace(/<[^>]*>/g, "");
              return (
                <>
                  <TabPane tabId={it.id} key={it.id}>
                    <FiTrash2
                      style={{
                        float: "right",
                        marginBottom: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDelete(it.id)}
                    />
                    <div className="mt-2">
                      <textarea
                        cols="150"
                        rows="20"
                        className="form-control"
                        id={it.id}
                        value={plainText}
                      ></textarea>
                    </div>
                  </TabPane>
                </>
              );
            })}
          </TabContent>
        </div>
        <div className="mt-2">
          <button
            id="copyButton"
            className="btn btn-outline-success"
            onClick={handleCopyLogic}
          >
            Copy
          </button>
          <button style={{ marginLeft:'5px',marginRight:'5px' }}
            target="_blank"
            className="btn btn-outline-success"

          >
            Print
          </button>
          <button
            target="_blank"
            className="btn btn-outline-success"
            onClick={handlePdfLogic}
          >
            PDF
          </button>
          {/* <button
            id="convertButton"
            className="btn btn-outline-success"
            onClick={handleDocumentLogic}
          >
            Document
          </button> */}
        </div>
      </div>
    </main>
  );
}
